<template>
    <div :id="cmsBlock.anchorTag" class="component-container white">
        <div class="player-wrapper">
            <PodcastPlayer :podcastCode="cmsBlock.podcast.data.attributes.iFrameCode"
                :appleLink="cmsBlock.podcast.data.attributes.appleLink" />
        </div>
    </div>
</template>

<script>
import PodcastPlayer from '@/components/PageBuilder/Elements/PodcastPlayer'

export default {
    name: 'SimplePodcastBlock',

    props: [
        'cmsBlock'
    ],

    components: {
        PodcastPlayer
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .player-wrapper {
        max-width: $container-width;
        margin: 0 auto;
        padding: 0 getSpacing('padding-default-horizontal', 'desktop');

        @include breakpoint('tablet') {
            padding: 0 getSpacing('padding-default-horizontal', 'tablet');
        }
        @include breakpoint('mobile') {
            padding: 0 getSpacing('padding-default-horizontal', 'mobile');
        }
    }
}
</style>


